import { Box, Chip, Switch, Tooltip, useTheme } from "@mui/material";
import Header from "components/Header";
import StyledDataGrid from "components/datagrids/StyledDataGrid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetUsersQuery } from "services/utils.service";
import { tokens } from "theme";
import dataGridStyles from "../../styles/dataGridStyles";
import { useDeleteRecord, useActiveToggleRecord } from "hooks";
import { toast } from "react-toastify";

const Users = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const { deleteRecord } = useDeleteRecord();
  const { activeToggleRecord } = useActiveToggleRecord();
  // queries
  const {
    data = {},
    isLoading,
    refetch,
  } = useGetUsersQuery(
    {
      params: {
        page: page + 1,
        limit: pageSize,
      },
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { items: usersData = [], count } = data ?? {};

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const styles = dataGridStyles(theme.palette.mode);
  const navigate = useNavigate();

  const columns = [
    {
      field: "fullName",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <span
          style={{
            textTransform: "capitalize",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "phone",
      headerName: "Contact",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => <span>{params.value || "NA"}</span>,
    },
    {
      field: "isSuspended",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.value === true ? "Inactive" : "Active"}
          color={params.value === true ? "error" : "success"}
          size="small"
          variant="outlined"
        />
      ),
    },
    {
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        const onToggleStatus = async (evt) => {
          const payload = {
            modelName: "User",
            status: !params.row.isSuspended,
            modelId: params.row._id,
          };
          try {
            await activeToggleRecord(payload, refetch);
            toast.success("User status update successfully");
          } catch (err) {
            console.log(err);
          }
        };
        return (
          <Box display="flex" justifyContent="center" gap={2}>
            <Tooltip title={params.row.isSuspended ? "Inactive" : "Active"}>
              <Switch
                size="small"
                checked={!params.row.isSuspended}
                onChange={onToggleStatus}
                sx={{
                  "&.MuiSwitch-root .MuiSwitch-switchBase": {
                    color: "black",
                  },

                  "&.MuiSwitch-root .Mui-checked": {
                    color: colors.blueAccent[600],
                  },
                }}
              />
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Box
        sx={{
          ...styles.headingsContainer,
          ...styles.dFlex,
          ...styles.alignItemsStart,
          ...styles.justifyContentBetween,
        }}
      >
        <Box sx={styles.mainHeadings}>
          <Header title="USERS" subtitle="Managing the Users" />
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.formContainer,
        }}
      >
        <StyledDataGrid
          rows={usersData}
          columns={columns}
          getRowId={(row) => row._id}
          loading={isLoading}
          pagination
          page={page}
          pageSize={pageSize}
          rowCount={count}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          className="no-shaddow"
          noResultText="No users found."
        />
      </Box>
    </Box>
  );
};

export default Users;
