import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "components/Header";
import AddColorStockModal from "components/modals/AddColorStockModal";
import AddStockModal from "components/modals/AddStockModal";
import {
  productInitialValues,
  productValidationSchema,
} from "constants/schemas";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateProductMutation,
  useEditProductMutation,
  useGetBrandsQuery,
  useGetCaptionTagQuery,
  useGetCategoriesQuery,
  useGetClothLengthQuery,
  useGetColorsQuery,
  useGetDiscountQuery,
  useGetFabricQuery,
  useGetOccasionsQuery,
  useGetPatternsQuery,
  useGetProductsQuery,
  useGetSectionsQuery,
  useGetSizeTypesQuery,
  useGetSubCategoriesQuery,
} from "services";
import { arraysAreEqual } from "utils/common";
import dataGridStyles from "../../styles/dataGridStyles";
import { tokens } from "theme";
import { PlayArrow } from "@mui/icons-material";
import VideoPlayer from "./VideoPlayer";

const Add = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const theme = useTheme();
  const styles = dataGridStyles(theme.palette.mode);
  const colorsStyle = tokens(theme.palette.mode);
  // hooks
  const navigate = useNavigate();
  const { id: productId } = useParams();

  // state
  const [show, setShow] = useState(false);
  const [showColor, setShowColor] = useState(false);

  // mutation
  const [createProduct] = useCreateProductMutation();
  const [editProduct] = useEditProductMutation();

  // query
  const { data: productData } = useGetProductsQuery(
    { id: productId },
    {
      skip: !productId,
      refetchOnMountOrArgChange: true,
    }
  );

  const getPreFilledValues = (productId, productData) => {
    if (productId && productData) {
      const {
        name,
        sku,
        description,
        additionalInformation,
        seo,
        attributes,
        prices,
        stock,
        images,
        mainImage,
        categoryId,
        subCategoryId,
        isSize,
        sizeType,
      } = productData;
      const {
        brand,
        sections,
        occasion,
        fabric,
        length,
        pattern,
        color,
        tags,
        discount: discountLabel,
      } = attributes;
      const { keywords, metaTitle, metaDescription } = seo;
      const { originalPrice, sellingPrice, discount } = prices?.b2cPrice || {};
      return {
        name,
        sku,
        description,
        additionalInformation,
        brand,
        sections,
        occasion,
        fabric,
        length,
        pattern,
        color,
        tags,
        discountLabel,
        originalPrice,
        sellingPrice,
        keywords,
        metaTitle,
        metaDescription,
        stock,
        images,
        mainImage,
        discount,
        categoryId: categoryId?._id ?? categoryId,
        isSize,
        sizeType,
        subCategoryId: subCategoryId?._id ?? subCategoryId,
      };
    }
    return productInitialValues;
  };

  const formik = useFormik({
    initialValues: getPreFilledValues(productId, productData),
    enableReinitialize: true,
    validationSchema: productValidationSchema,
    onSubmit: (values) => {
      const {
        brand,
        sections,
        occasion,
        fabric,
        length,
        pattern,
        color,
        tags,
        keywords,
        metaTitle,
        metaDescription,
        originalPrice,
        sellingPrice,
        discount,
        discountLabel,
        ...rest
      } = values;
      const payload = {
        ...rest,
        attributes: {
          brand,
          sections,
          occasion,
          fabric,
          length,
          pattern,
          color,
          tags,
          discount: discountLabel,
        },
        seo: {
          keywords,
          metaTitle,
          metaDescription,
        },
        b2cPrice: {
          originalPrice,
          sellingPrice,
          discount,
        },
      };
      if (productId) {
        editProduct({
          id: productId,
          body: payload,
        })
          .unwrap()
          .then((result) => {
            if (result?._id) {
              navigate("/products");
            }
          });
      } else {
        createProduct(payload)
          .unwrap()
          .then((result) => {
            if (result?._id) {
              navigate("/products");
            }
          });
      }
    },
  });

  // query
  const { data: categories } = useGetCategoriesQuery();
  const { data: subCategories } = useGetSubCategoriesQuery(
    { catId: formik.values.categoryId },
    {
      skip: !formik.values.categoryId,
    }
  );
  const { data: brands } = useGetBrandsQuery();
  const { data: sections = [] } = useGetSectionsQuery();
  const { data: ocassions = [] } = useGetOccasionsQuery();
  const { data: fabrics = [] } = useGetFabricQuery();
  const { data: lengths = [] } = useGetClothLengthQuery();
  const { data: colors = [] } = useGetColorsQuery();
  const { data: patterns = [] } = useGetPatternsQuery();
  const { data: captions = [] } = useGetCaptionTagQuery();
  const { data: sizeTypes = [] } = useGetSizeTypesQuery();
  const { data: discountData = [] } = useGetDiscountQuery();

  const filter = createFilterOptions();

  const handleChange = (event, newValue) => {
    const updatedNewValue = newValue.map((item) => {
      if (typeof item === "string") {
        return item;
      }
      if (item.inputValue) {
        return item.inputValue;
      }
      return item;
    });

    const uniqueValues = Array.from(new Set(updatedNewValue));
    formik.setFieldValue("keywords", uniqueValues);
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    margin: "25px 0",
    textAlign: "left",
  };

  const thTdStyle = {
    padding: "12px",
    borderBottom: "1px solid #ddd",
  };

  const handleRemove = (index, type) => {
    const updatedData = formik.values?.[type].filter((_, i) => i !== index);
    formik.setFieldValue(type, updatedData);
  };

  return (
    <Box m="20px">
      <Box sx={styles.mainHeadings}>
        <Header
          title={`${productId ? "EDIT" : "CREATE"} PRODUCT`}
          subtitle={`${productId ? "Edit Product" : "Create a New Product"}`}
        />
        {productId && (
          <Box display="flex" justifyContent="end" mt="20px">
            <Button
              onClick={() => navigate("/products")}
              color="secondary"
              variant="contained"
              sx={styles.buttonMd}
            >
              Back to Products
            </Button>
          </Box>
        )}
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            ...styles.formContainer,
          }}
          className="extra-space"
        >
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="SKU ID"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.sku}
              name="sku"
              error={!!formik.touched.sku && !!formik.errors.sku}
              helperText={formik.touched.sku && formik.errors.sku}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              name="name"
              error={!!formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
              sx={{ gridColumn: "span 2" }}
              className="input"
            />
            <TextField
              fullWidth
              variant="filled"
              type="number"
              label="Price"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.originalPrice}
              name="originalPrice"
              error={
                !!formik.touched.originalPrice && !!formik.errors.originalPrice
              }
              helperText={
                formik.touched.originalPrice && formik.errors.originalPrice
              }
              sx={{ gridColumn: "span 2" }}
              InputLabelProps={{ shrink: true }}
              className="input"
            />
            <TextField
              fullWidth
              variant="filled"
              type="number"
              label="Selling Price"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.sellingPrice}
              name="sellingPrice"
              error={
                !!formik.touched.sellingPrice && !!formik.errors.sellingPrice
              }
              helperText={
                formik.touched.sellingPrice && formik.errors.sellingPrice
              }
              sx={{ gridColumn: "span 2" }}
              InputLabelProps={{ shrink: true }}
              className="input"
            />
            <TextField
              fullWidth
              variant="filled"
              type="number"
              label="Discount"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.discount}
              name="discount"
              error={!!formik.touched.discount && !!formik.errors.discount}
              helperText={formik.touched.discount && formik.errors.discount}
              sx={{ gridColumn: "span 2" }}
              InputLabelProps={{ shrink: true }}
              className="input"
            />
            <Autocomplete
              className="autocomplete-1"
              options={brands}
              getOptionLabel={(option) => option.name}
              onBlur={formik.handleBlur}
              onChange={(_, value) =>
                formik.setFieldValue("brand", value?.name)
              }
              name="brand"
              sx={{ gridColumn: "span 2" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="filled"
                  label="Select Brand"
                  error={!!formik.touched.brand && !!formik.errors.brand}
                  helperText={formik.touched.brand && formik.errors.brand}
                  InputLabelProps={{ shrink: true }}
                />
              )}
              value={
                brands?.find((item) => item.name === formik.values.brand) ||
                null
              }
            />
            {categories?.length > 0 &&
            formik.values.categoryId !== undefined ? (
              <Autocomplete
                key={productId}
                className="autocomplete-1"
                options={categories}
                getOptionLabel={(option) => option.name}
                onBlur={formik.handleBlur}
                onChange={(_, value) =>
                  formik.setFieldValue("categoryId", value?._id)
                }
                name="categoryId"
                sx={{ gridColumn: "span 2" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="filled"
                    label="Select Category"
                    error={
                      !!formik.touched.categoryId && !!formik.errors.categoryId
                    }
                    helperText={
                      formik.touched.categoryId && formik.errors.categoryId
                    }
                  />
                )}
                value={
                  categories.find(
                    (category) => category._id === formik.values.categoryId
                  ) || null
                }
              />
            ) : (
              <></>
            )}

            {subCategories?.length > 0 && (
              <Autocomplete
                className="autocomplete-1"
                options={subCategories}
                getOptionLabel={(option) => option.name}
                onBlur={formik.handleBlur}
                onChange={(_, value) =>
                  formik.setFieldValue("subCategoryId", value?._id)
                }
                name="subCategoryId"
                sx={{ gridColumn: "span 2" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="filled"
                    label="Select Sub Category"
                  />
                )}
                value={
                  subCategories?.length > 0
                    ? subCategories?.find(
                        (subCategory) =>
                          subCategory?._id === formik.values.subCategoryId
                      )
                    : null
                }
              />
            )}
            <Autocomplete
              className="autocomplete-1"
              options={sections}
              getOptionLabel={(option) => option.name}
              onBlur={formik.handleBlur}
              multiple
              onChange={(_, value) => {
                const sectionNames = value.map((item) => item.name);
                formik.setFieldValue("sections", sectionNames);
              }}
              name="sections"
              sx={{ gridColumn: "span 2" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="filled"
                  label="Home Page Section"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              value={sections?.filter((item) =>
                formik.values.sections.includes(item.name)
              )}
            />
            <Autocomplete
              className="autocomplete-1"
              options={discountData}
              getOptionLabel={(option) => option.name}
              onBlur={formik.handleBlur}
              onChange={(_, value) => {
                formik.setFieldValue("discountLabel", value?.name);
              }}
              name="discountLabel"
              sx={{ gridColumn: "span 2" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="filled"
                  label="Discount Label"
                />
              )}
              value={discountData?.find(
                (item) => item.name === formik.values.discountLabel
              )}
            />
            <Autocomplete
              className="autocomplete-1"
              options={ocassions}
              getOptionLabel={(option) => option.name}
              onBlur={formik.handleBlur}
              multiple
              onChange={(_, value) => {
                const occasionNames = value.map((item) => item.name);
                formik.setFieldValue("occasion", occasionNames);
              }}
              name="occasion"
              sx={{ gridColumn: "span 2" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="filled"
                  label="Occasion"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              value={ocassions?.filter((item) =>
                formik.values.occasion.includes(item.name)
              )}
            />
            <Autocomplete
              className="autocomplete-1"
              options={fabrics}
              getOptionLabel={(option) => option.name}
              onBlur={formik.handleBlur}
              multiple
              onChange={(_, value) => {
                const fabricNames = value.map((item) => item.name);
                formik.setFieldValue("fabric", fabricNames);
              }}
              name="fabric"
              sx={{ gridColumn: "span 2" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="filled"
                  label="Fabric"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              value={fabrics?.filter((item) =>
                formik.values.fabric.includes(item.name)
              )}
            />
            <Autocomplete
              className="autocomplete-1"
              options={lengths}
              getOptionLabel={(option) => option.name}
              onBlur={formik.handleBlur}
              onChange={(_, value) =>
                formik.setFieldValue("length", value?.name)
              }
              name="length"
              sx={{ gridColumn: "span 2" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="filled"
                  label="Length"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              value={lengths?.find(
                (item) => item.name === formik.values?.length
              )}
            />
            <Autocomplete
              className="autocomplete-1"
              options={patterns}
              getOptionLabel={(option) => option.name}
              onBlur={formik.handleBlur}
              onChange={(_, value) =>
                formik.setFieldValue("pattern", value?.name)
              }
              name="pattern"
              sx={{ gridColumn: "span 2" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="filled"
                  label="Pattern"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              value={patterns?.find(
                (item) => item.name === formik.values.pattern
              )}
            />
            <Autocomplete
              className="autocomplete-1"
              options={captions}
              getOptionLabel={(option) => option.name}
              onBlur={formik.handleBlur}
              multiple
              onChange={(_, value) => {
                const tagNames = value.map((item) => item.name);
                formik.setFieldValue("tags", tagNames);
              }}
              name="tags"
              sx={{ gridColumn: "span 2" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="filled"
                  label="Tag/Label"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              value={captions?.filter((item) =>
                formik.values.tags.includes(item.name)
              )}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Description"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.description}
              name="description"
              error={
                !!formik.touched.description && !!formik.errors.description
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              sx={{ gridColumn: "span 4" }}
              className="input"
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Additional Information"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.additionalInformation}
              name="additionalInformation"
              className="input"
              error={
                !!formik.touched.additionalInformation &&
                !!formik.errors.additionalInformation
              }
              helperText={
                formik.touched.additionalInformation &&
                formik.errors.additionalInformation
              }
              sx={{ gridColumn: "span 4" }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.isSize}
                  onChange={formik.handleChange}
                  name="isSize"
                  sx={{
                    "&.MuiSwitch-root .MuiSwitch-switchBase": {
                      color: "black",
                    },

                    "&.MuiSwitch-root .Mui-checked": {
                      color: colorsStyle.blueAccent[600],
                    },
                  }}
                />
              }
              label="Size Available"
              sx={{ gridColumn: formik.values.isSize ? "span 2" : "span 4" }}
            />
            {formik.values.isSize && (
              <Autocomplete
                className="autocomplete-1"
                options={sizeTypes}
                getOptionLabel={(option) => option.name}
                onBlur={formik.handleBlur}
                onChange={(_, value) =>
                  formik.setFieldValue("sizeType", value?.values)
                }
                name="sizeType"
                sx={{ gridColumn: "span 2" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="filled"
                    label="Size Type"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                value={sizeTypes?.find((item) =>
                  arraysAreEqual(item?.values, formik.values.sizeType)
                )}
              />
            )}
            <Box
              sx={{
                gridColumn: "span 4",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 style={{ margin: 0 }}>Stocks :</h3>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setShow(true)}
                sx={styles.buttonMd}
              >
                Add Stock
              </Button>
            </Box>
            {formik.errors.stock && (
              <Box sx={{ gridColumn: "span 4" }}>
                <Typography color="error">{formik.errors.stock}</Typography>
              </Box>
            )}
            {formik.values.stock?.length > 0 && (
              <Box sx={{ gridColumn: "span 4" }}>
                <table style={tableStyle}>
                  <thead>
                    <tr>
                      <th style={thTdStyle}>Size</th>
                      <th style={thTdStyle}>Quantity</th>
                      <th style={thTdStyle}>Unit Price</th>
                      <th style={thTdStyle}>Selling Price</th>
                      <th style={thTdStyle}>Product Type</th>
                      <th style={thTdStyle}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formik.values.stock.map((item, index) => (
                      <tr key={index}>
                        <td style={thTdStyle}>{item.size}</td>
                        <td style={thTdStyle}>{item.minqty}</td>
                        <td style={thTdStyle}>{item.unitPrice}</td>
                        <td style={thTdStyle}>{item.sellingPrice}</td>
                        <td style={thTdStyle}>{item.productType}</td>
                        <td style={thTdStyle}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleRemove(index, "stock")}
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
            )}

            <Box
              sx={{
                gridColumn: "span 4",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 style={{ margin: 0 }}>Colors :</h3>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setShowColor(true)}
                sx={styles.buttonMd}
              >
                Add Color
              </Button>
            </Box>
            {formik.errors.color && (
              <Box sx={{ gridColumn: "span 4" }}>
                <Typography color="error">{formik.errors.color}</Typography>
              </Box>
            )}
            {formik.values.color?.length > 0 && (
              <Box sx={{ gridColumn: "span 4" }}>
                <table style={tableStyle}>
                  <thead>
                    <tr>
                      <th style={thTdStyle}>Name</th>
                      <th style={thTdStyle}>Hex Code</th>
                      <th style={thTdStyle}>Main Image</th>
                      <th style={thTdStyle}>Other Images</th>
                      <th style={thTdStyle}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formik.values.color.map((item, index) => (
                      <tr key={index}>
                        <td style={thTdStyle}>{item.name}</td>
                        <td style={thTdStyle}>{item.hexCode}</td>
                        <td style={thTdStyle}>
                          {item?.images?.mainImage?.mediaType === "image" ? (
                            <img
                              src={item?.images?.mainImage?.url}
                              style={{ width: "100px" }}
                            />
                          ) : (
                            <VideoPlayer src={item?.images?.mainImage?.url} />
                          )}
                        </td>
                        <td
                          style={{
                            ...thTdStyle,
                            overflow: "auto",
                          }}
                        >
                          <Box sx={{ display: "flex", columnGap: "8px" }}>
                            {item?.images?.images?.length > 0 &&
                              item.images.images.map((image) => {
                                return (
                                  <>
                                    {image.mediaType === "image" ? (
                                      <img
                                        src={image.url}
                                        style={{ width: "100px" }}
                                      />
                                    ) : (
                                      <VideoPlayer src={image.url} />
                                    )}
                                  </>
                                );
                              })}
                          </Box>
                        </td>
                        <td style={thTdStyle}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleRemove(index, "color")}
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
            )}

            <h3>SEO Information</h3>
            <br />
            <Autocomplete
              className="autocomplete-1"
              fullWidth
              multiple
              value={formik.values.keywords}
              onChange={handleChange}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                if (params.inputValue !== "") {
                  filtered.push(params.inputValue);
                }

                return filtered;
              }}
              options={formik.values.keywords}
              getOptionLabel={(option) => option}
              renderOption={(props, option) => <li {...props}>{option}</li>}
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Keywords"
                  placeholder="Create options"
                  error={
                    formik.touched.keywords && Boolean(formik.errors.keywords)
                  }
                />
              )}
              sx={{ gridColumn: "span 4" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Meta Title"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.metaTitle}
              name="metaTitle"
              error={!!formik.touched.metaTitle && !!formik.errors.metaTitle}
              helperText={formik.touched.metaTitle && formik.errors.metaTitle}
              sx={{ gridColumn: "span 4" }}
              className="input"
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Meta Description"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.metaDescription}
              name="metaDescription"
              error={
                !!formik.touched.metaDescription &&
                !!formik.errors.metaDescription
              }
              helperText={
                formik.touched.metaDescription && formik.errors.metaDescription
              }
              sx={{ gridColumn: "span 4" }}
              className="input"
            />
          </Box>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              sx={styles.buttonMd}
            >
              {productId ? "Update" : "Create"}
            </Button>
          </Box>
        </Box>
      </form>
      <AddStockModal formik={formik} show={show} setShow={setShow} />
      <AddColorStockModal
        formik={formik}
        colorsData={colors}
        show={showColor}
        setShow={setShowColor}
      />
    </Box>
  );
};

export default Add;
