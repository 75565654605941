import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EyeIcon from "@mui/icons-material/Visibility";
import { Box, Button, TextField, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import { useDebounce, useDeleteRecord } from "hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useGetProductsQuery } from "services";
import { tokens } from "theme";
import dataGridStyles from "../../styles/dataGridStyles";
import { useState } from "react";
import StyledDataGrid from "components/datagrids/StyledDataGrid";
import { setConfirmModalConfig } from "store/slices/utilSlice";
import { useDispatch, useSelector } from "react-redux";
import { Search } from "@mui/icons-material";

const List = () => {
  const [page, setPage] = useState(0); // Note: DataGrid uses 0-based indexing for pages
  const [pageSize, setPageSize] = useState(10);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchName = searchParams.get("name");
  const debouncedNameQry = useDebounce(searchName, 500);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth.user);
  // queries
  const {
    data = {},
    isLoading,
    refetch,
  } = useGetProductsQuery(
    {
      params: {
        page: page + 1,
        limit: pageSize,
        search: debouncedNameQry ?? undefined,
      },
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { items: productsData = [], count } = data ?? {};

  // hooks
  const { deleteRecord } = useDeleteRecord();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const styles = dataGridStyles(theme.palette.mode);
  const navigate = useNavigate();

  const columns = [
    {
      field: "sku",
      headerName: "SKU ID",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <span
          style={{
            textTransform: "capitalize",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "mainImage",
      headerName: "Image",
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.attributes?.color?.[0]?.images?.mainImage?.mediaType === "image" ? (
            <img
              src={params.row.attributes?.color?.[0]?.images?.mainImage?.url}
              alt={params.row.name}
              style={{ width: "50px", height: "50px", objectFit: "contain" }}
            />
          ) : (
            <Box sx={{ position: 'relative', width: '50px', height: '50px' }}>
              <video
                src={params.row.attributes?.color?.[0]?.images?.mainImage?.url}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
          )}
        </>
      ),
    },
    {
      field: "prices.b2cPrice.sellingPrice",
      headerName: "Price",
      flex: 1,
      renderCell: (params) => {
        return <>&#8377;{params.row.prices.b2cPrice.sellingPrice}</>;
      },
    },
    {
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" gap={2}>
          {userInfo?.roles === "ADMIN" && (
            <>
              <Link to={`/products/edit/${params.row._id}`}>
                <EditIcon />
              </Link>
              <span
                className="cursor-pointer"
                onClick={() => {
                  const payload = {
                    modelName: "Product",
                    status: true,
                    modelId: params.row._id,
                  };
                  dispatch(
                    setConfirmModalConfig({
                      visible: true,
                      data: {
                        onSubmit: () => deleteRecord(payload, refetch),
                        content: {
                          heading: "Delete Product?",
                          description:
                            "Are you sure you want to delete this product?",
                        },
                      },
                    })
                  );
                }}
              >
                <DeleteIcon />
              </span>
            </>
          )}
          <Link to={`/products/${params.row._id}`}>
            <EyeIcon />
          </Link>
        </Box>
      ),
    },
  ];

  const handleChange = (evt) => {
    setSearchParams({
      name: evt.target.value,
    });
  };

  return (
    <Box m="20px">
      <Box
        sx={{
          ...styles.headingsContainer,
          ...styles.dFlex,
          ...styles.alignItemsStart,
          ...styles.justifyContentBetween,
        }}
      >
        <Box sx={styles.mainHeadings}>
          <Header title="PRODUCTS" subtitle="Managing the Products" />
        </Box>
        {userInfo?.roles === "ADMIN" && (
          <Box>
            <Button
              sx={styles.buttonMd}
              LinkComponent={Link}
              to="/products/add"
            >
              <AddOutlinedIcon sx={{ mr: "10px" }} />
              Add Product
            </Button>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          ...styles.formContainer,
        }}
      >
        <Box
          sx={{
            ...styles.searchBar,
          }}
        >
          <TextField
            placeholder="Search by name"
            onChange={handleChange}
            value={searchName}
            InputProps={{
              endAdornment: <Search />,
            }}
          />
        </Box>

        <StyledDataGrid
          rows={productsData}
          columns={columns}
          getRowId={(row) => row._id}
          loading={isLoading}
          pagination
          page={page}
          pageSize={pageSize}
          rowCount={count}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          className="no-shaddow"
          noResultText="No products found."
        />
      </Box>
    </Box>
  );
};

export default List;
