import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EyeIcon from "@mui/icons-material/Visibility";
import { Box, Button, TextField, Tooltip, useTheme } from "@mui/material";
import Header from "components/Header";
import StyledDataGrid from "components/datagrids/StyledDataGrid";
import { useDebounce, useDeleteRecord } from "hooks";
import { useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { useGetCategoriesQuery } from "services";
import { setConfirmModalConfig } from "store/slices/utilSlice";
import { tokens } from "theme";
import dataGridStyles from "../../styles/dataGridStyles";
import CategoryIcon from "@mui/icons-material/Category";
import { Search } from "@mui/icons-material";

const List = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchName = searchParams.get("name");
  const catId = searchParams.get("catId");
  const debouncedNameQry = useDebounce(searchName, 500);
  const dispatch = useDispatch();
  // queries
  const {
    data: categoriesData = [],
    isLoading,
    refetch,
  } = useGetCategoriesQuery(
    { search: debouncedNameQry ?? undefined, catId: catId ?? undefined },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  // hooks
  const { deleteRecord } = useDeleteRecord();

  const theme = useTheme();
  const styles = dataGridStyles(theme.palette.mode);
  const colors = tokens(theme.palette.mode);
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <span
          style={{
            textTransform: "capitalize",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      renderCell: (params) => (
        <span
          style={{
            textTransform: "capitalize",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" gap={2}>
          <Link to={`/categories/edit/${params.row._id}`}>
            <EditIcon />
          </Link>
          <span
            className="cursor-pointer"
            onClick={() => {
              const payload = {
                modelName: "Category",
                status: true,
                modelId: params.row._id,
              };
              dispatch(
                setConfirmModalConfig({
                  visible: true,
                  data: {
                    onSubmit: () => deleteRecord(payload, refetch),
                    content: {
                      heading: "Delete Category?",
                      description:
                        "Are you sure you want to delete this category?",
                    },
                  },
                })
              );
            }}
          >
            <DeleteIcon />
          </span>
          <Link to={`/categories/${params.row._id}`}>
            <EyeIcon />
          </Link>
          {!catId && (
            <Tooltip title="Subcategory">
              <Link to={`/categories?catId=${params.row._id}`}>
                <CategoryIcon />
              </Link>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  const handleChange = (evt) => {
    setSearchParams({
      catId: catId ?? "",
      name: evt.target.value,
    });
  };

  return (
    <Box m="20px">
      <Box
        sx={{
          ...styles.headingsContainer,
          ...styles.dFlex,
          ...styles.alignItemsStart,
          ...styles.justifyContentBetween,
        }}
      >
        <Box sx={{ ...styles.mainHeadings }}>
          <Header title="CATEGORIES" subtitle="Managing the Categories" />
        </Box>
        <Box>
          <Button
            sx={styles.buttonMd}
            LinkComponent={Link}
            to={catId ? "/categories" : "/categories/add"}
          >
            {catId ? (
              "Back To Categories"
            ) : (
              <>
                <AddOutlinedIcon sx={{ mr: "10px" }} />
                Add Category
              </>
            )}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.formContainer,
        }}
      >
        <Box
          sx={{
            ...styles.searchBar,
          }}
        >
          <TextField
            placeholder="Search"
            onChange={handleChange}
            value={searchName}
            InputProps={{
              endAdornment: <Search />,
            }}
          />
        </Box>

        <StyledDataGrid
          rows={categoriesData}
          columns={columns}
          getRowId={(row) => row._id}
          loading={isLoading}
          noResultText="No categories found."
        />
      </Box>
    </Box>
  );
};

export default List;
