export const API_ENDPOINTS = {
  // AUTH
  SIGN_IN: "/admin/log-in",
  UPDATE_PROFILE: "/admin/profile",
  GET_PROFILE: "/admin/profile",
  CHANGE_PASSWORD: "/admin/change-password",
  GET_SUB_ADMINS: "/admin/sub-admins",
  CHANGE_SUB_ADMIN_PASSWORD: "/admin/change-subadmin-password",
  ADD_SUB_ADMIN: "/admin/sub-admins", 
  UPDATE_SUB_ADMIN: "/admin/sub-admins",
  GET_SUB_ADMIN_INFO: "/admin/sub-admins",
  // CATEGORIES
  GET_CATEGORIES: "/categories",
  CREATE_CATEGORY: "/categories",
  UPDATE_CATEGORY: "/categories",

  // COUPON
  CREATE_COUPON: "/coupons",
  GET_COUPON: "/coupons",

  // PRODUCTS
  GET_PRODUCTS: "/products",
  CREATE_PRODUCT: "/products",
  EDIT_PRODUCT: "/products",

  // MASTERS
  GET_SIZE_TYPES: "/masters/size-type",
  GET_CAPTION_TAG: "/masters/caption-tag",
  GET_CLOTH_LENGTH: "/masters/cloth-length",
  GET_COLORS: "/masters/colors",
  GET_DISCOUNT: "/masters/discount",
  GET_FABRIC: "/masters/fabric",
  GET_OCCASIONS: "/masters/occasions",
  GET_PATTERNS: "/masters/patterns",
  GET_BRANDS: "/masters/brands",
  GET_SECTIONS: "/masters/sections",
  GET_PAYMENT_MODES: "/masters/payment-modes",
  ADD_SIZE_TYPE: "/masters/size-type",
  ADD_CAPTION_TAG: "/masters/caption-tag",
  ADD_CLOTH_LENGTH: "/masters/cloth-length",
  ADD_COLOR: "/masters/colors",
  ADD_DISCOUNT: "/masters/discount",
  ADD_FABRIC: "/masters/fabric",
  ADD_OCCASION: "/masters/occasions",
  ADD_PATTERN: "/masters/patterns",
  ADD_SECTION: "/masters/sections",
  ADD_BRAND: "/masters/brands",
  ADD_PAYMENT_MODE: "/masters/payment-modes",
  EDIT_SIZE_TYPE: "/masters/size-type",
  EDIT_CAPTION_TAG: "/masters/caption-tag",
  EDIT_CLOTH_LENGTH: "/masters/cloth-length",
  EDIT_COLOR: "/masters/colors",
  EDIT_DISCOUNT: "/masters/discount",
  EDIT_FABRIC: "/masters/fabric",
  EDIT_OCCASION: "/masters/occasions",
  EDIT_PATTERN: "/masters/patterns",
  EDIT_SECTION: "/masters/sections",
  EDIT_BRAND: "/masters/brands",
  EDIT_PAYMENT_MODE: "/masters/payment-modes",

  // ORDERS
  GET_ORDERS: "/orders/all-orders",
  GET_ORDER_INFO: "/orders/order-detail",
  UPDATE_ORDER_STATUS: "/orders/update-order-status",
  REFUND_ORDER: "/orders/get-refund",

  // SHIPPING
  CREATE_SHIPMENT: "/shipping/shiprocket-create-shipment",
  GENERATE_AWB: "/shipping/shiprocket-generate-awb",
  GENERATE_LABEL: "/shipping/shiprocket-generate-label",
  GENERATE_MANIFEST: "/shipping/shiprocket-generate-manifest",
  GENERATE_PICKUP: "/shipping/shiprocket-generate-pickup",
  CREATE_QUICK_ORDER: "/shipping/shiprocket-create-quick-order",
  SHIPROCKET_RETURN_ORDER: "/shipping/shiprocket-return-order",

  // UTILS
  DELETE_ROW: "/admin/delete-row",
  ACTIVE_TOGGLE_ROW: "/admin/active-inactive-row",
  GET_GENERAL_SETTINGS: "/admin/settings",
  UPDATE_GENERAL_SETTINGS: "/admin/settings",
  GET_DASHBOARD_INFO: "/utils/dashboard",
  GET_USERS: "/users/list",

  // STATIC PAGE
  CREATE_STATIC_PAGE: "/pages",
  GET_STATIC_PAGE: "/pages",
};
