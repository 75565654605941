import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useGetOrderInfoQuery, useUpdateStatusMutation } from "services";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setConfirmModalConfig } from "store/slices/utilSlice";
import parse from "html-react-parser";
import { GLOBAL_SETTINGS } from "constants/constantData";
import { formatTimestamp } from "utils/common";
import {
  Download,
  LocalShipping,
  Payment,
  Schedule,
  Receipt,
  Event,
  Phone,
  Email,
  EventNote,
  CreditCard,
} from "@mui/icons-material";
import dataGridStyles from "../../styles/dataGridStyles";
import { useAcceptOrder } from "hooks";

const View = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const { id } = useParams();
  const [orderStatus, setOrderStatus] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const styles = dataGridStyles(theme.palette.mode);

  const { data: orderData, refetch } = useGetOrderInfoQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });

  const [updateStatus] = useUpdateStatusMutation();

  const { onClickAccept } = useAcceptOrder({ refetch });

  useEffect(() => {
    if (orderData?.status) {
      setOrderStatus(orderData.status);
    }
  }, [orderData?.status]);

  const shipmentInfo = orderData?.shipments?.length
    ? orderData?.shipments[0]
    : {};

  const handleCancelOrder = async (orderId) => {
    try {
      const result = await updateStatus({
        orderId,
        status: orderData?.status == "68" ? "2" : "45",
      });
      if (result?.data?._id) {
        refetch();
        toast.success("Order cancelled successfully!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box m={2}>
      {/* Header Section */}
      <Box display="flex" justifyContent="space-between" mb={3}>
        <Typography variant="h4" fontWeight="bold">
          Order Details
        </Typography>
        <Box display="flex" gap={2}>
          {["9"]?.includes(orderData?.status) && (
            <Button
              variant="contained"
              color="success"
              onClick={() => onClickRefund(orderData?._id)}
            >
              Initiate Refund
            </Button>
          )}
          {["68"]?.includes(orderData?.status) && (
            <Button
              variant="contained"
              color="success"
              onClick={() => onClickAccept(orderData?._id)}
            >
              Accept Order
            </Button>
          )}
          {!["7", "2", "45", "9"]?.includes(orderData?.status) && (
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                dispatch(
                  setConfirmModalConfig({
                    visible: true,
                    data: {
                      onSubmit: () => handleCancelOrder(orderData?._id),
                      content: {
                        heading: "Cancel Order",
                        description:
                          "Are you sure you want to cancel this order?",
                      },
                    },
                  })
                );
              }}
            >
              Cancel Order
            </Button>
          )}
          <Button
            sx={styles.buttonMd}
            variant="contained"
            color="secondary"
            onClick={() => navigate("/orders")}
          >
            Back to Orders
          </Button>
        </Box>
      </Box>
      {/* Order Info Section */}
      <Grid container spacing={3}>
        {/* Order Info Card */}
        <Grid item xs={12} md={6} p={3}>
          <Card
            sx={{
              backgroundColor: "#f5f5f5",
              borderRadius: 2,
              boxShadow: 3,
              minHeight: "300px",
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                fontWeight="bold"
                align="center"
                color="primary"
                gutterBottom
              >
                Order Info
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 1,
                  marginTop: 5,
                }}
              >
                <CreditCard
                  sx={{ fontSize: 20, marginRight: 1, color: "gray" }}
                />
                <strong style={{ color: "gray" }}>Order ID:</strong>
                <span style={{ marginLeft: 8 }}>{orderData?.orderId}</span>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}
              >
                <EventNote
                  sx={{ fontSize: 20, marginRight: 1, color: "gray" }}
                />
                <strong style={{ color: "gray" }}>Status:</strong>
                <span style={{ marginLeft: 8, color: "#ff9800" }}>
                  {orderData?.statusText}
                </span>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}
              >
                <Payment sx={{ fontSize: 20, marginRight: 1, color: "gray" }} />
                <strong style={{ color: "gray" }}>Total Price:</strong>
                <span style={{ marginLeft: 8 }}>
                  {parse(GLOBAL_SETTINGS.CURRENCY)} {orderData?.totalPrice}
                </span>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}
              >
                <Payment sx={{ fontSize: 20, marginRight: 1, color: "gray" }} />
                <strong style={{ color: "gray" }}>Payment Method:</strong>
                <span style={{ marginLeft: 8 }}>
                  {orderData?.paymentMethod}
                </span>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}
              >
                <EventNote
                  sx={{ fontSize: 20, marginRight: 1, color: "gray" }}
                />
                <strong style={{ color: "gray" }}>Paid At:</strong>
                <span style={{ marginLeft: 8 }}>
                  {formatTimestamp(orderData?.paidAt)}
                </span>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}>
                <EventNote
                  sx={{ fontSize: 20, marginRight: 1, color: "gray" }}
                />
                <strong style={{ color: "gray" }}>Created At:</strong>
                <span style={{ marginLeft: 8 }}>
                  {formatTimestamp(orderData?.createdAt)}
                </span>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}>
                <EventNote
                  sx={{ fontSize: 20, marginRight: 1, color: "gray" }}
                />
                <strong style={{ color: "gray" }}>Coupon Code:</strong>
                <span style={{ marginLeft: 8 }}>
                  {orderData?.coupoun?.code || "N/A"}
                </span>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}
              >
                <EventNote
                  sx={{ fontSize: 20, marginRight: 1, color: "gray" }}
                />
                <strong style={{ color: "gray" }}>Coupon Discount:</strong>
                <span style={{ marginLeft: 8 }}>
                  <span>₹</span>
                  {orderData?.coupoun?.cDiscount || 0}
                </span>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}
              >
                <EventNote
                  sx={{ fontSize: 20, marginRight: 1, color: "gray" }}
                />
                <strong style={{ color: "gray" }}>Shipping Charges:</strong>
                <span style={{ marginLeft: 8 }}>
                  <span>₹</span>
                  {orderData?.totalShipping || 0}
                </span>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Shipping Address Card */}
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              backgroundColor: "#f5f5f5",
              borderRadius: 2,
              boxShadow: 3,
              minHeight: "300px",
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                fontWeight="bold"
                align="center"
                color="primary"
                gutterBottom
              >
                Shipping Address
              </Typography>
              <Box sx={{ margin: "40px auto" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 1,
                  }}
                >
                  <LocalShipping
                    sx={{ fontSize: 20, marginRight: 1, color: "gray" }}
                  />
                  <strong style={{ color: "gray" }}>Ordered By:</strong>
                  <span style={{ marginLeft: 8 }}>
                    {orderData?.shippingAddress?.fullName}
                  </span>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 1,
                  }}
                >
                  <Phone sx={{ fontSize: 20, marginRight: 1, color: "gray" }} />
                  <strong style={{ color: "gray" }}>Phone:</strong>
                  <span style={{ marginLeft: 8 }}>
                    {orderData?.shippingAddress?.phone}
                  </span>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 1,
                  }}
                >
                  <Email sx={{ fontSize: 20, marginRight: 1, color: "gray" }} />
                  <strong style={{ color: "gray" }}>Email:</strong>
                  <span style={{ marginLeft: 8 }}>
                    {orderData?.shippingAddress?.email}
                  </span>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 1,
                  }}
                >
                  <LocalShipping
                    sx={{ fontSize: 20, marginRight: 1, color: "gray" }}
                  />
                  <strong style={{ color: "gray" }}>Address:</strong>
                  <span style={{ marginLeft: 8 }}>
                    {orderData?.shippingAddress?.addressLine1},{" "}
                    {orderData?.shippingAddress?.addressLine2},{" "}
                    {orderData?.shippingAddress?.city},{" "}
                    {orderData?.shippingAddress?.state},{" "}
                    {orderData?.shippingAddress?.country} -{" "}
                    {orderData?.shippingAddress?.postalCode}
                  </span>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* shipment info */}
      <Grid container spacing={3} mt={3}>
        <Grid item xs={12}>
          <Card
            sx={{ backgroundColor: "#f9f9f9", borderRadius: 2, boxShadow: 3 }}
          >
            <CardContent>
              <Typography
                variant="h6"
                fontWeight="bold"
                align="center"
                color="primary"
                gutterBottom
              >
                Shipment Info
              </Typography>

              {/* Buttons positioned on the top right */}
              <Box display="flex" justifyContent="flex-end" gap={2} mb={2}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<Download />}
                  href={shipmentInfo?.label_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download Label
                </Button>
                {shipmentInfo?.manifest_url && (
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    startIcon={<Download />}
                    href={shipmentInfo?.manifest_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download Manifest
                  </Button>
                )}
              </Box>

              {/* Grid for two columns */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ color: "text.secondary", marginBottom: 1 }}>
                    <LocalShipping
                      fontSize="small"
                      sx={{ verticalAlign: "middle", marginRight: 1 }}
                    />
                    <strong>Courier Name:</strong>{" "}
                    {shipmentInfo?.courier_name || "N/A"}
                  </Typography>

                  <Typography sx={{ color: "text.secondary", marginBottom: 1 }}>
                    <Receipt
                      fontSize="small"
                      sx={{ verticalAlign: "middle", marginRight: 1 }}
                    />
                    <strong>
                      <span>AWB Code:</span>
                    </strong>{" "}
                    <span style={{ color: "#ff9800" }}>
                      {shipmentInfo?.awb_code || "N/A"}
                    </span>
                  </Typography>

                  <Typography sx={{ color: "text.secondary", marginBottom: 2 }}>
                    <Event
                      fontSize="small"
                      sx={{ verticalAlign: "middle", marginRight: 1 }}
                    />
                    <strong>Shipping Status:</strong>{" "}
                    {shipmentInfo?.shipping_status || "N/A"}
                  </Typography>

                  <Typography sx={{ color: "text.secondary", marginBottom: 2 }}>
                    <Payment
                      fontSize="small"
                      sx={{ verticalAlign: "middle", marginRight: 1 }}
                    />
                    <strong>Shipment Payment Type:</strong>{" "}
                    {orderData?.paymentMethod === "Cash on Delivery"
                      ? "Postpaid"
                      : "Prepaid"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography sx={{ color: "text.secondary", marginBottom: 2 }}>
                    <Schedule
                      fontSize="small"
                      sx={{ verticalAlign: "middle", marginRight: 1 }}
                    />
                    <strong>Pickup Scheduled Date:</strong>{" "}
                    {shipmentInfo?.pickup_scheduled_date || "N/A"}
                  </Typography>

                  <Typography sx={{ color: "text.secondary", marginBottom: 2 }}>
                    <Event
                      fontSize="small"
                      sx={{ verticalAlign: "middle", marginRight: 1 }}
                    />
                    <strong>Pickup Token Number:</strong>{" "}
                    {shipmentInfo?.pickup_token_number || "N/A"}
                  </Typography>

                  <Typography sx={{ color: "text.secondary", marginBottom: 2 }}>
                    <Receipt
                      fontSize="small"
                      sx={{ verticalAlign: "middle", marginRight: 1 }}
                    />
                    <strong>Shipment ID:</strong>{" "}
                    {shipmentInfo?.shipment_id || "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Products Section */}
      <Box mt={3}>
        <Typography
          variant="h5"
          fontWeight="bold"
          color="primary"
          textAlign="center"
          mb={3}
        >
          Products
        </Typography>
        <Grid container spacing={3}>
          {orderData?.products?.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  backgroundColor: "#f5f5f5",
                  borderRadius: 2,
                  boxShadow: 3,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardContent sx={{ padding: 2 }}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "150px",
                      backgroundImage: `url(${
                        item?.mainImage ?? item?.productId?.mainImage
                      })`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      marginBottom: 2,
                      borderRadius: 1,
                    }}
                  />
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ marginBottom: 1, color: "text.primary" }}
                  >
                    {item?.productId?.name}
                  </Typography>
                  <Typography sx={{ color: "text.secondary", marginBottom: 1 }}>
                    <strong>Price:</strong> {parse(GLOBAL_SETTINGS.CURRENCY)}
                    {item?.price}
                  </Typography>
                  <Typography sx={{ color: "text.secondary", marginBottom: 1 }}>
                    <strong>Quantity:</strong> {item?.quantity}
                  </Typography>
                  <Typography sx={{ color: "text.secondary", marginBottom: 1 }}>
                    <strong>Size:</strong> {item?.selectedSize}
                  </Typography>
                  <Typography sx={{ color: "text.secondary", marginBottom: 1 }}>
                    <strong>Color:</strong> {item?.selectedColor?.name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default View;
