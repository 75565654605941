import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EyeIcon from "@mui/icons-material/Visibility";
import { Box, Button, TextField, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import { useDebounce, useDeleteRecord } from "hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useGetProductsQuery } from "services";
import { tokens } from "theme";
import dataGridStyles from "../../styles/dataGridStyles";
import { useState } from "react";
import { useGetStaticPageListQuery } from "services/staticpage.service";
import StyledDataGrid from "components/datagrids/StyledDataGrid";
import { Search } from "@mui/icons-material";

const List = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchName = searchParams.get("name");
  const debouncedNameQry = useDebounce(searchName, 500);
  // queries
  const {
    data: pagesData = {},
    isLoading,
    refetch,
  } = useGetStaticPageListQuery(
    { params: { search: debouncedNameQry ?? undefined } },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  // hooks
  const { deleteRecord } = useDeleteRecord();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const styles = dataGridStyles(theme.palette.mode);
  const navigate = useNavigate();
  const handleChange = (evt) => {
    setSearchParams({
      name: evt.target.value,
    });
  };
  const columns = [
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      renderCell: (params) => (
        <span
          style={{
            textTransform: "capitalize",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "slug",
      headerName: "Slug",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <span
          style={{
            textTransform: "capitalize",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" gap={2}>
          <Link to={`/static-pages/edit/${params.row.slug}`}>
            <EditIcon />
          </Link>
          <Link to={`/static-pages/${params.row.slug}`}>
            <EyeIcon />
          </Link>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Box
        sx={{
          ...styles.headingsContainer,
          ...styles.dFlex,
          ...styles.alignItemsStart,
          ...styles.justifyContentBetween,
        }}
      >
        <Box sx={styles.mainHeadings}>
          <Header title="STATIC PAGES" subtitle="Managing the Static Pages" />
        </Box>
        {/* <Box>
          <Button
            sx={styles.buttonMd}
            LinkComponent={Link}
            to="/static-pages/add"
          >
            <AddOutlinedIcon sx={{ mr: "10px" }} />
            Add Page
          </Button>
        </Box> */}
      </Box>

      <Box
        sx={{
          ...styles.formContainer,
        }}
      >
        <Box
          sx={{
            ...styles.searchBar,
          }}
        >
          <TextField
            placeholder="Search"
            onChange={handleChange}
            value={searchName}
            InputProps={{
              endAdornment: <Search />,
            }}
          />
        </Box>

        <StyledDataGrid
          rows={pagesData}
          columns={columns}
          getRowId={(row) => row._id}
          loading={isLoading}
          noResultText="No pages found"
        />
      </Box>
    </Box>
  );
};

export default List;
