import { API_ENDPOINTS } from "../constants/endpoints";
import { apiSlice, handleResponse } from "./base.service";

export const orderApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: ({params}) => ({
        url: API_ENDPOINTS.GET_ORDERS,
        method: "GET",
        params
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    getOrderInfo: builder.query({
      query: (id) => ({
        url: `${API_ENDPOINTS.GET_ORDER_INFO}/${id}`,
        method: "GET",
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    updateStatus: builder.mutation({
      query: (body) => ({
        url: API_ENDPOINTS.UPDATE_ORDER_STATUS,
        method: "PUT",
        body,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    refundOrder: builder.mutation({
      query: (body) => ({
        url: API_ENDPOINTS.REFUND_ORDER,
        method: "POST",
        body,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
  }),
});

export const { useGetOrdersQuery, useGetOrderInfoQuery, useUpdateStatusMutation, useRefundOrderMutation } = orderApiSlice;
